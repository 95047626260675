@charset 'UTF-8';
@keyframes opacityShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pathmove {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 71px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 120px;
    opacity: 0;
  }
}

@keyframes pathmoveSP {
  0% {
    height: 0;
    top: 40px;
    opacity: 0;
  }
  30% {
    height: 71px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 120px;
    opacity: 0;
  }
}
